import React from 'react'

import Layout from '../../components/layout'
import { Services } from '../../components/services'
import Seo from '../../components/seo'
const TorunServices = () => {

    return (
        <Layout torun>
            <Services torun/>
        </Layout>
    )
}

export default TorunServices