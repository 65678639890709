import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'

import { device  } from '../../utils/breakpoints'


import { AiOutlineArrowRight } from 'react-icons/ai'



const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.grayWhite};
    padding: 3rem 5vw;
    min-height: 100vh;
`

const StyledContainer = styled(Container) `
    gap: 4rem;
    justify-items: center;
    align-items: center;
`

//Top
const ContainerOnTopPlaces = styled.div `
    display: grid;
    gap: 1rem;
    justify-items: center;
`


const StyledInformation = styled.span `
    font-weight: 500;
    color: ${({ theme }) => theme.colors.green};
    font-size: ${({ theme }) => theme.fonts.size.m};
    line-height: 150%;
`

//Center
const ContainerServices = styled.div `
    display: grid;
    justify-items: center;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: auto;
    width: 100%;
    height: 100%;
    gap: 2rem;
    

    @media ${device.tabletL} {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        
    }
`

const ContainerPlace = styled.div `
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    
    @media ${device.tabletL} {
        .arrow-icon-services {
            display: none;
        }
    }

    .image-styles-places {
        display: grid;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 9;
        ::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: #E8E8E8;
            opacity: 0%;
           
        }

        @media ${device.tabletL} {
            ::after {
                opacity: 45%;
            }
        }
    }

    :hover {
        @media ${device.tabletL} {
            .arrow-icon-services {
                display: block;
            }
        }


        border: 1px solid #2D6A4F;

        .image-styles-places {
        display: grid;
        width: 100%;
        height: 100%;
        position: relative;

        ::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: #E8E8E8;
            opacity: 0%;
        }

    }
    }
`

const PlaceTextBottom = styled.div `
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: grid;
    width: 100%;
    height: 80px;
    background-color: rgba(255,255,255, 0.8);

 
`
const ContainerTextIcon = styled.div `
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-items: center;
`   

//Link

const StyledLinkPlace = styled(StyledLink) `
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 450px;
`





export const Services = ({ lodz, torun, bydgoszcz }) => {

    if(lodz) {

    return (
        <StyledWrapper>
        <StyledContainer>
            <ContainerOnTopPlaces>
                <Heading h1 as='h1'>
                    Zabiegi w BodyMed Group Łódz
                </Heading>
                <StyledInformation>
                    Prosimy wybierz kategorię zabiegów:
                </StyledInformation>
            </ContainerOnTopPlaces> 

            <ContainerServices>
                <StyledLinkPlace to='lodz-kosmetologia-estetyczna'>
                    <ContainerPlace>
                        <StaticImage
                            src="../../images/kosmetologia-estetyczna.jpg"
                            alt="Kosmetologia estetyczna w BodyMed Group Lodz" 
                            title="Kosmetologia estetyczna" 
                            layout="fullWidth"
                            placeholder="dominantColor"
                            className="image-styles-places"
                            imgClassName="imageStyles"
                        />
                        <PlaceTextBottom>
                            <ContainerTextIcon>
                                <AiOutlineArrowRight className='arrow-icon-services' color='#2D6A4F' size='24' />
                                <Heading h4 as='h4'>Kosmetologia estetyczna</Heading>
                            </ContainerTextIcon>
                        </PlaceTextBottom>  
                    </ContainerPlace>
                </StyledLinkPlace>

                <StyledLinkPlace to='lodz-laseroterapia'>
                    <ContainerPlace>
                        <StaticImage
                            src="../../images/laseroterapia.jpg"
                            alt="Laseroterapia w BodyMed Group Lodz" 
                            title="Laseroterapia" 
                            layout="fullWidth"
                            placeholder="dominantColor"
                            className="image-styles-places"
                            imgClassName="imageStyles"
                        />
                        <PlaceTextBottom>
                            <ContainerTextIcon>
                                <AiOutlineArrowRight className='arrow-icon-services' color='#2D6A4F' size='24' />
                                <Heading h4 as='h4'>Laseroterapia</Heading>
                            </ContainerTextIcon>
                        </PlaceTextBottom>  
                    </ContainerPlace>
                </StyledLinkPlace>

                <StyledLinkPlace to='lodz-modelowanie-sylwetki'>
                    <ContainerPlace>
                        <StaticImage
                            src="../../images/modelowanie-sylwetki.jpg"
                            alt="Modelowanie sylwetki w BodyMed Group Lodz" 
                            title="Modelowanie sylwetki" 
                            layout="fullWidth"
                            placeholder="dominantColor"
                            className="image-styles-places"
                            imgClassName="imageStyles"
                        />
                        <PlaceTextBottom>
                            <ContainerTextIcon>
                                <AiOutlineArrowRight className='arrow-icon-services' color='#2D6A4F' size='24' />
                                <Heading h4 as='h4'>Modelowanie sylwetki</Heading>
                            </ContainerTextIcon>
                        </PlaceTextBottom>  
                    </ContainerPlace>
                </StyledLinkPlace>


            </ContainerServices>

        </StyledContainer>
    </StyledWrapper>
    )}

    else if(bydgoszcz) {

        return (
            <StyledWrapper>
            <StyledContainer>
                <ContainerOnTopPlaces>
                    <Heading h1 as='h1'>
                    Zabiegi w BodyMed Group Bydgoszcz
                    </Heading>
                    <StyledInformation>
                        Prosimy wybierz kategorię zabiegów:
                    </StyledInformation>
                </ContainerOnTopPlaces> 
                
                <ContainerServices>
    
                    <StyledLinkPlace to='bydgoszcz-kosmetologia-estetyczna'>
                        <ContainerPlace>
                            <StaticImage
                                src="../../images/kosmetologia-estetyczna.jpg"
                                alt="Kosmetologia estetyczna w BodyMed Group Bydgoszcz" 
                                title="Kosmetologia estetyczna" 
                                layout="fullWidth"
                                placeholder="dominantColor"
                                className="image-styles-places"
                                imgClassName="imageStyles"
                            />
                            <PlaceTextBottom>
                                <ContainerTextIcon>
                                    <AiOutlineArrowRight className='arrow-icon-services' color='green' size='24' />
                                    <Heading h4 as='h4'>Kosmetologia estetyczna</Heading>
                                </ContainerTextIcon>
                            </PlaceTextBottom>  
                        </ContainerPlace>
                    </StyledLinkPlace>
    
                    <StyledLinkPlace to='bydgoszcz-laseroterapia'>
                        <ContainerPlace>
                            <StaticImage
                                src="../../images/laseroterapia.jpg"
                                alt="Laseroterapia BodyMed Group Bydgoszcz" 
                                title="Laseroterapia" 
                                layout="fullWidth"
                                placeholder="dominantColor"
                                className="image-styles-places"
                                imgClassName="imageStyles"
                            />
                            <PlaceTextBottom>
                                <ContainerTextIcon>
                                    <AiOutlineArrowRight className='arrow-icon-services' color='green' size='24' />
                                    <Heading h4 as='h4'>Laseroterapia</Heading>
                                </ContainerTextIcon>
                            </PlaceTextBottom>  
                        </ContainerPlace>
                    </StyledLinkPlace>
    
                    <StyledLinkPlace to='bydgoszcz-modelowanie-sylwetki'>
                        <ContainerPlace>
                            <StaticImage
                                src="../../images/modelowanie-sylwetki.jpg"
                                alt="Modelowanie sylwetki w BodyMed Group Bydgoszcz" 
                                title="Modelowanie sylwetki" 
                                layout="fullWidth"
                                placeholder="dominantColor"
                                className="image-styles-places"
                                imgClassName="imageStyles"
                            />
                            <PlaceTextBottom>
                                <ContainerTextIcon>
                                    <AiOutlineArrowRight className='arrow-icon-services' color='#1B8640' size='24' />
                                    <Heading h4 as='h4'>Modelowanie sylwetki</Heading>
                                </ContainerTextIcon>
                            </PlaceTextBottom>  
                        </ContainerPlace>
                    </StyledLinkPlace>
    
                </ContainerServices>
    
            </StyledContainer>
        </StyledWrapper>
        )}

        else if(torun) {

            return (
                <StyledWrapper>
                <StyledContainer>
                    <ContainerOnTopPlaces>
                        <Heading h1 as='h1'>
                        Zabiegi w BodyMed Group Torun
                        </Heading>
                        <StyledInformation>
                            Prosimy wybierz kategorię zabiegów:
                        </StyledInformation>
                    </ContainerOnTopPlaces> 
                    <ContainerServices>
        
                        <StyledLinkPlace to='torun-kosmetologia-estetyczna'>
                            <ContainerPlace>
                                <StaticImage
                                    src="../../images/kosmetologia-estetyczna.jpg"
                                    alt="Kosmetologia estetyczna w BodyMed Group Torun" 
                                    title="Kosmetologia estetyczna" 
                                    layout="fullWidth"
                                    placeholder="dominantColor"
                                    className="image-styles-places"
                                    imgClassName="imageStyles"
                                />
                                <PlaceTextBottom>
                                    <ContainerTextIcon>
                                        <AiOutlineArrowRight className='arrow-icon-services' color='green' size='24' />
                                        <Heading h4 as='h4'>Kosmetologia estetyczna</Heading>
                                    </ContainerTextIcon>
                                </PlaceTextBottom>  
                            </ContainerPlace>
                        </StyledLinkPlace>
        
                        <StyledLinkPlace to='torun-laseroterapia'>
                            <ContainerPlace>
                                <StaticImage
                                    src="../../images/laseroterapia.jpg"
                                    alt="Laseroterapia w BodyMed Group Torun" 
                                    title="Laseroterapia" 
                                    layout="fullWidth"
                                    placeholder="dominantColor"
                                    className="image-styles-places"
                                    imgClassName="imageStyles"
                                />
                                <PlaceTextBottom>
                                    <ContainerTextIcon>
                                        <AiOutlineArrowRight className='arrow-icon-services' color='green' size='24' />
                                        <Heading h4 as='h4'>Laseroterapia</Heading>
                                    </ContainerTextIcon>
                                </PlaceTextBottom>  
                            </ContainerPlace>
                        </StyledLinkPlace>
        
                        <StyledLinkPlace to='torun-modelowanie-sylwetki'>
                            <ContainerPlace>
                                <StaticImage
                                    src="../../images/modelowanie-sylwetki.jpg"
                                    alt="Modelowanie sylwetki w BodyMed Group Torun" 
                                    title="Modelowanie sylwetki" 
                                    layout="fullWidth"
                                    placeholder="dominantColor"
                                    className="image-styles-places"
                                    imgClassName="imageStyles"
                                />
                                <PlaceTextBottom>
                                    <ContainerTextIcon>
                                        <AiOutlineArrowRight className='arrow-icon-services' color='#1B8640' size='24' />
                                        <Heading h4 as='h4'>Modelowanie sylwetki</Heading>
                                    </ContainerTextIcon>
                                </PlaceTextBottom>  
                            </ContainerPlace>
                        </StyledLinkPlace>

                        <StyledLinkPlace to='torun-stylizacja-paznokci'>
                            <ContainerPlace>
                                <StaticImage
                                    src="../../images/stylizacja-paznokci.jpg"
                                    alt="Stylizacja paznokci w BodyMed Group Torun" 
                                    title="Stylizacja paznokci" 
                                    layout="fullWidth"
                                    placeholder="dominantColor"
                                    className="image-styles-places"
                                    imgClassName="imageStyles"
                                />
                                <PlaceTextBottom>
                                    <ContainerTextIcon>
                                        <AiOutlineArrowRight className='arrow-icon-services' color='#1B8640' size='24' />
                                        <Heading h4 as='h4'>Stylizacja paznokci i rzęs</Heading>
                                    </ContainerTextIcon>
                                </PlaceTextBottom>  
                            </ContainerPlace>
                        </StyledLinkPlace>
                    </ContainerServices>
                </StyledContainer>
            </StyledWrapper>
            )}
}



// <StyledLinkPlace to='lodz-strefa-fitness'>
// <ContainerPlace>
//     <StaticImage
//         src="../../images/strefa-fitness-lodz.jpg"
//         alt="Strefa fitness w BodyMed Group Lodz" 
//         title="Strefa fitness" 
//         layout="fullWidth"
//         placeholder="dominantColor"
//         className="image-styles-places"
//         imgClassName="imageStyles"
//     />
//     <PlaceTextBottom>
//         <ContainerTextIcon>
//             <AiOutlineArrowRight className='arrow-icon-services' color='#2D6A4F' size='24' />
//             <Heading h4 as='h4'>Strefa fitness</Heading>
//         </ContainerTextIcon>
//     </PlaceTextBottom>  
// </ContainerPlace>
// </StyledLinkPlace>